var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6661e8475c14d362755be1bc9481954f52ea7d8a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { clientEnv } from '@/env/client';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: clientEnv.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.04,
  profilesSampleRate: 0.33,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.5,
  environment: clientEnv.NEXT_PUBLIC_ENV,
  debug: clientEnv.NEXT_PUBLIC_SENTRY_DEBUG,
  ignoreErrors: [
    'https://react.dev/link/hydration-mismatch',
    'There was an error while hydrating but React was able to recover by instead client rendering the entire root.',
    'https://react.dev/errors/418',
    'Text content does not match server-rendered HTML.',
    // iubenda
    'bannerXclose',
    'ibFindAllVideos is not defined',
    // cloudflare
    't.sendObjectBeacon',
  ],
});
